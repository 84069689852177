var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [!_vm.wasiat || [0, 1, 3].includes(_vm.wasiat.status) ? _c('editable-part-d', {
    attrs: {
      "wasiat": _vm.wasiat
    }
  }) : _vm._e(), _vm.wasiat && _vm.wasiat.status == 2 ? _c('readonly-part-d', {
    attrs: {
      "wasiat": _vm.wasiat
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }