var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_vm.userRole != 'Client' ? _c('div', {
    staticStyle: {
      "position": "absolute",
      "right": "2%",
      "top": "2%"
    }
  }) : _vm._e(), _c('h5', {
    staticClass: "text-center",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("wasiatasas.testator-desc")) + " ")]), _c('form', {
    staticClass: "login-form"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm.userRole == 'Staff' && _vm.pengesahan ? _c('div', {
    staticClass: "col-md-12 text-right mt-2"
  }, [_c('b-button', {
    staticClass: "btn-sm btn-primary m-1",
    on: {
      "click": function ($event) {
        return _vm.showIc('front');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("view-mykad")) + " ")])], 1) : _vm._e(), _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.name.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "full_name"
    }
  }, [_vm._v(_vm._s(_vm.$t("fullname")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('i', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mdi mdi-information",
    attrs: {
      "title": "Nama Penuh mestilah sama seperti ejaan MyKad"
    }
  }), _c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.designation,
      expression: "form.designation"
    }],
    staticClass: "form-control",
    staticStyle: {
      "border-bottom-right-radius": "0",
      "border-top-right-radius": "0"
    },
    attrs: {
      "name": "",
      "id": "designation"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "designation", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.changeDesignation]
    }
  }, _vm._l(_vm.designations, function (designation) {
    return _c('option', {
      key: designation,
      domProps: {
        "value": designation
      }
    }, [_vm._v(" " + _vm._s(designation) + " ")]);
  }), 0)]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.name.$model,
      expression: "$v.form.name.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Nama Penuh",
      "name": "fullname"
    },
    domProps: {
      "value": _vm.$v.form.name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _vm.$v.form.name.$error && !_vm.$v.form.name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.name")) + " ")]) : _vm._e()])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-5"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.ic_number.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "ic_number"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("mykad")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('the-mask', {
    staticClass: "form-control",
    attrs: {
      "id": "ic_number",
      "mask": ['######-##-####'],
      "readonly": "true"
    },
    model: {
      value: _vm.$v.form.ic_number.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.form.ic_number, "$model", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "$v.form.ic_number.$model"
    }
  }), !_vm.$v.form.ic_number.minLength ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mykad-should")) + " " + _vm._s(_vm.$v.form.ic_number.$params.minLength.min) + " " + _vm._s(_vm.$t("validation.character-wo")) + " ")]) : _vm._e(), !_vm.$v.form.ic_number.maxLength ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mykad-should")) + " " + _vm._s(_vm.$v.form.ic_number.$params.maxLength.max) + " " + _vm._s(_vm.$t("validation.character-wo")) + " ")]) : _vm._e(), _vm.$v.form.ic_number.$error && !_vm.$v.form.ic_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mykad")) + " ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "col-md-7"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', {
    attrs: {
      "for": "other_ic_number"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("old-ic")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.other_ic_number,
      expression: "form.other_ic_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "other_ic_number",
      "required": ""
    },
    domProps: {
      "value": _vm.form.other_ic_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "other_ic_number", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.gender.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "gender"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("gender")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.gender.$model,
      expression: "$v.form.gender.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": "gender"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.gender, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.gender")) + " --")]), _vm._l(_vm.genders, function (gender, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": gender.split(':')[0]
      }
    }, [_vm._v(" " + _vm._s(gender.split(":")[1]) + " ")]);
  })], 2), _vm.$v.form.gender.$error && !_vm.$v.form.gender.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(_vm._s(_vm.$t("gender")) + " " + _vm._s(_vm.$t("validation.cannot-blank")))]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.religion.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "religion"
    }
  }, [_vm._v(_vm._s(_vm.$t("religion"))), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.religion.$model,
      expression: "$v.form.religion.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.religion, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.changeReligion]
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.religion")) + " --")]), _vm._l(_vm.religions, function (religion) {
    return _c('option', {
      key: religion,
      domProps: {
        "value": religion
      }
    }, [_vm._v(" " + _vm._s(religion) + " ")]);
  })], 2), _vm.$v.form.religion.$error && !_vm.$v.form.religion.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(_vm._s(_vm.$t("religion")) + " " + _vm._s(_vm.$t("validation.cannot-blank")))]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.race.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "race"
    }
  }, [_vm._v(_vm._s(_vm.$t("race"))), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.race.$model,
      expression: "$v.form.race.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": "race"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.race, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.changeRace]
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.race")) + " --")]), _vm._l(_vm.races, function (race) {
    return _c('option', {
      key: race,
      domProps: {
        "value": race
      }
    }, [_vm._v(" " + _vm._s(race) + " ")]);
  })], 2), _vm.$v.form.race.$error && !_vm.$v.form.race.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(_vm._s(_vm.$t("race")) + " " + _vm._s(_vm.$t("validation.cannot-blank")))]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.citizenship.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "citizenship"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("nationality")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.citizenship.$model,
      expression: "$v.form.citizenship.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": "citizenship"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.citizenship, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.nationality")) + " --")]), _vm._l(_vm.nationalities, function (nationality, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": nationality.split(':')[0]
      }
    }, [_vm._v(" " + _vm._s(nationality.split(":")[1]) + " ")]);
  })], 2), _vm.$v.form.citizenship.$error && !_vm.$v.form.citizenship.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(_vm._s(_vm.$t("nationality")) + " " + _vm._s(_vm.$t("validation.cannot-blank")))]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.email.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v(_vm._s(_vm.$t("email")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "lowercase",
      rawName: "v-lowercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.email.$model,
      expression: "$v.form.email.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "email"
    },
    domProps: {
      "value": _vm.$v.form.email.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.email, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), !_vm.$v.form.email.email ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.invalid-email")) + " ")]) : _vm._e(), _vm.$v.form.email.$error && !_vm.$v.form.email.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.email")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.mobile_number.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "mobile_number"
    }
  }, [_vm._v(_vm._s(_vm.$t("mobile-no")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.mobile_number.$model,
      expression: "$v.form.mobile_number.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "mobile_number"
    },
    domProps: {
      "value": _vm.$v.form.mobile_number.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.mobile_number, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.mobile_number.$error && !_vm.$v.form.mobile_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mobile-no")) + " ")]) : _vm._e(), _vm.$v.form.mobile_number.$error && !_vm.$v.form.mobile_number.numeric ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mobile-in-number")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "home_phone_number"
    }
  }, [_vm._v(_vm._s(_vm.$t("home-telno")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.home_phone_number,
      expression: "form.home_phone_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "home_phone_number"
    },
    domProps: {
      "value": _vm.form.home_phone_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "home_phone_number", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.address.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "address"
    }
  }, [_vm._v(_vm._s(_vm.$t("mailing-address"))), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('textarea', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.address.$model,
      expression: "$v.form.address.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "id": "address",
      "rows": "2"
    },
    domProps: {
      "value": _vm.$v.form.address.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.address, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.address.$error && !_vm.$v.form.address.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.correspondence-address")) + " ")]) : _vm._e()])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "d-block",
    attrs: {
      "for": "postcode"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("postcode")))]), _c('span', {
    staticStyle: {
      "text-decoration": "underline",
      "font-size": "11px",
      "color": "#333"
    },
    on: {
      "click": _vm.openpostcode
    }
  }, [_vm._v(_vm._s(_vm.$t("add-new")))])])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy",
      value: _vm.form.postcode,
      expression: "form.postcode",
      modifiers: {
        "lazy": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "postcode"
    },
    domProps: {
      "value": _vm.form.postcode
    },
    on: {
      "blur": function ($event) {
        return _vm.getPostcode(1);
      },
      "change": function ($event) {
        return _vm.$set(_vm.form, "postcode", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "city"
    }
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.city,
      expression: "form.city"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "city",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "city", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.state,
      expression: "form.state"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "state",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "state", $event.target.value);
      }
    }
  })])])]), _c('hr', {
    staticClass: "primary"
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.address_2.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "city"
    }
  }, [_vm._v(_vm._s(_vm.$t("permanent-address"))), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.sameAddress,
      expression: "form.sameAddress"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "type": "checkbox",
      "id": "customCheck1",
      "value": "1"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.sameAddress) ? _vm._i(_vm.form.sameAddress, "1") > -1 : _vm.form.sameAddress
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.form.sameAddress,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "sameAddress", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "sameAddress", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "sameAddress", $$c);
        }
      }, _vm.copyAddress]
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "customCheck1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("same-address")) + " ")])]), _c('textarea', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.address_2.$model,
      expression: "$v.form.address_2.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "id": "address_2",
      "rows": "2",
      "readonly": _vm.form.sameAddress == 1
    },
    domProps: {
      "value": _vm.$v.form.address_2.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.address_2, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.address_2.$error && !_vm.$v.form.address_2.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(_vm._s(_vm.$t("permanent-address")) + " " + _vm._s(_vm.$t("validation.cannot-blank")))]) : _vm._e()])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "d-block",
    attrs: {
      "for": "postcode"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("postcode")))]), _c('span', {
    staticStyle: {
      "text-decoration": "underline",
      "font-size": "11px",
      "color": "#333"
    },
    on: {
      "click": _vm.openpostcode
    }
  }, [_vm._v(_vm._s(_vm.$t("add-new")))])])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy",
      value: _vm.form.postcode_2,
      expression: "form.postcode_2",
      modifiers: {
        "lazy": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "postcode_2",
      "readonly": _vm.form.sameAddress == 1
    },
    domProps: {
      "value": _vm.form.postcode_2
    },
    on: {
      "blur": function ($event) {
        return _vm.getPostcode(2);
      },
      "change": function ($event) {
        return _vm.$set(_vm.form, "postcode_2", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "city"
    }
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.city_2,
      expression: "form.city_2"
    }],
    staticClass: "form-control",
    staticStyle: {
      "text-transform": "uppercase"
    },
    attrs: {
      "type": "text",
      "id": "city_2",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.city_2
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "city_2", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.state_2,
      expression: "form.state_2"
    }],
    staticClass: "form-control",
    staticStyle: {
      "text-transform": "uppercase"
    },
    attrs: {
      "type": "text",
      "id": "state_2",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.state_2
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "state_2", $event.target.value);
      }
    }
  })])])]), _c('hr', {
    staticClass: "primary"
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_vm._v(_vm._s(_vm.$t("mykadcopy-front")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', [_c('small', [_c('i', [_vm._v("(" + _vm._s(_vm.$t("pls-separate-mykad")) + ")")])])]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    },
    on: {
      "click": function ($event) {
        return _vm.openModal('mykad-front');
      }
    }
  }, [_vm.mykad.frontUrl ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.mykad.frontUrl + ');'
  }) : _vm._e(), !_vm.mykad.frontUrl ? _c('label', {
    staticClass: "upload-photo-dark text-center"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("choose.mykadfront")))])]) : _vm._e()])]), _c('div', {
    staticClass: "d-flex justify-content-center mt-2"
  }, [_c('b-button', {
    staticClass: "btn-sm btn-primary m-1",
    on: {
      "click": function ($event) {
        return _vm.showIc('front');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")])], 1)]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "upload-photo-1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("mykadcopy-back"))), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', [_c('small', [_c('i', [_vm._v("(" + _vm._s(_vm.$t("pls-separate-mykad")) + ")")])])]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    },
    on: {
      "click": function ($event) {
        return _vm.openModal('mykad-back');
      }
    }
  }, [_vm.mykad.backUrl ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.mykad.backUrl + ');'
  }) : _vm._e(), !_vm.mykad.backUrl ? _c('label', {
    staticClass: "upload-photo-dark text-center"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("choose.mykadback")))])]) : _vm._e()])]), _c('div', {
    staticClass: "d-flex justify-content-center mt-2"
  }, [_c('b-button', {
    staticClass: "btn-sm btn-primary m-1",
    on: {
      "click": function ($event) {
        return _vm.showIc('back');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")])], 1)])])])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }), _vm.wasiat ? _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save-next")) + " "), _vm.submit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()]) : _vm._e()])])]), _c('b-modal', {
    ref: "modalPostcode",
    staticClass: "modal fade",
    attrs: {
      "title": _vm.$t('add-newpostcode'),
      "ok-title": _vm.$t('save'),
      "cancel-title": _vm.$t('cancel')
    },
    on: {
      "ok": _vm.storePostcode
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("postcode")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formPostcode.postcode,
      expression: "formPostcode.postcode"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.formPostcode.postcode
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.formPostcode, "postcode", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("city")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.formPostcode.city,
      expression: "formPostcode.city"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.formPostcode.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.formPostcode, "city", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("state")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.formPostcode.state,
      expression: "formPostcode.state"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.formPostcode.state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.formPostcode, "state", $event.target.value);
      }
    }
  })])])])]), _c('b-modal', {
    ref: "modalOcrFront",
    staticClass: "modal fade",
    attrs: {
      "id": "modalOcrFront",
      "title": "Capture Camera",
      "ok-title": "Mulakan",
      "hide-footer": ""
    }
  }, [!this.$isMobile() ? _c('div', {
    staticClass: "video-options"
  }, [_c('select', {
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        return _vm.selectCamera($event);
      }
    }
  }, _vm._l(_vm.videoDevices, function (device) {
    return _c('option', {
      key: device.deviceId,
      domProps: {
        "value": device.deviceId
      }
    }, [_vm._v(" " + _vm._s(device.label) + " ")]);
  }), 0)]) : _vm._e(), this.$isMobile() ? _c('div', [_c('b-button', {
    staticClass: "btn-sm",
    on: {
      "click": _vm.mobileChangeCamera
    }
  }, [_vm._v(" " + _vm._s(_vm.mobileCamera))])], 1) : _vm._e(), _c('div', {
    attrs: {
      "id": _vm.selfie == null && !_vm.noCamera ? 'content' : 'content-ic'
    }
  }, [_c('video', {
    ref: "video",
    staticClass: "camera-stream",
    staticStyle: {
      "border-radius": "10px",
      "box-shadow": "0 3px 5px 0 rgba(47, 85, 212, 0.3)",
      "z-index": "0",
      "position": "relative"
    },
    attrs: {
      "playsinline": "",
      "autoplay": "",
      "width": "70%"
    }
  }), _c('canvas', {
    ref: "canvas",
    staticStyle: {
      "display": "none"
    }
  }), _c('p', [_c('img')])]), _c('button', {
    staticClass: "btn btn-primary btn-xsm w-100",
    on: {
      "click": function ($event) {
        return _vm.capture('mykad-front');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("take-mykad-front")) + " ")])]), _c('b-modal', {
    ref: "modalOcrBack",
    staticClass: "modal fade",
    attrs: {
      "id": "modalOcrBack",
      "title": "Capture Camera",
      "ok-title": "Mulakan",
      "hide-footer": ""
    }
  }, [!this.$isMobile() ? _c('div', {
    staticClass: "video-options"
  }, [_c('select', {
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        return _vm.selectCamera($event);
      }
    }
  }, _vm._l(_vm.videoDevices, function (device) {
    return _c('option', {
      key: device.deviceId,
      domProps: {
        "value": device.deviceId
      }
    }, [_vm._v(" " + _vm._s(device.label) + " ")]);
  }), 0)]) : _vm._e(), this.$isMobile() ? _c('div', [_c('b-button', {
    staticClass: "btn-sm",
    on: {
      "click": _vm.mobileChangeCamera
    }
  }, [_vm._v(" " + _vm._s(_vm.mobileCamera))])], 1) : _vm._e(), _c('div', {
    attrs: {
      "id": _vm.selfie == null && !_vm.noCamera ? 'content' : 'content-ic'
    }
  }, [_c('video', {
    ref: "video",
    staticClass: "camera-stream",
    staticStyle: {
      "border-radius": "10px",
      "box-shadow": "0 3px 5px 0 rgba(47, 85, 212, 0.3)",
      "z-index": "0",
      "position": "relative"
    },
    attrs: {
      "playsinline": "",
      "autoplay": "",
      "width": "70%"
    }
  }), _c('canvas', {
    ref: "canvas",
    staticStyle: {
      "display": "none"
    }
  }), _c('p', [_c('img')])]), _c('button', {
    staticClass: "btn btn-primary btn-xsm w-100",
    on: {
      "click": function ($event) {
        return _vm.capture('mykad-back');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("take-mykad-back")) + " ")])]), _c('b-modal', {
    ref: "modalSelectfront",
    staticClass: "modal fade",
    attrs: {
      "id": "modalSelect",
      "title": "Please Select",
      "ok-title": "Mulakan",
      "hide-footer": ""
    },
    on: {
      "close": _vm.closeEkyc
    }
  }, [_c('div', [_c('label', {
    staticClass: "upload-photo-dark text-center btn-sm btn-primary",
    attrs: {
      "for": "upload-photo-2"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("upload-documents")))])]), _c('input', {
    staticClass: "upload-photo-input",
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo-2",
      "accept": "image/*"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files, 'mykad-front');
      }
    }
  })]), _c('div', [_c('label', {
    staticClass: "upload-photo-dark text-center btn-sm btn-primary",
    on: {
      "click": function ($event) {
        return _vm.openmodalcapture('mykad-front');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("capture-picture")))])])])]), _c('b-modal', {
    ref: "modalSelect",
    staticClass: "modal fade",
    attrs: {
      "id": "modalSelect",
      "title": "Please Select",
      "ok-title": "Mulakan",
      "hide-footer": ""
    },
    on: {
      "close": _vm.closeEkyc
    }
  }, [_c('div', [_c('label', {
    staticClass: "upload-photo-dark text-center btn-sm btn-primary",
    attrs: {
      "for": "upload-photo-1"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("upload-documents")))])]), _c('input', {
    staticClass: "upload-photo-input",
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo-1",
      "accept": "image/*"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files, 'mykad-back');
      }
    }
  })]), _c('div', [_c('label', {
    staticClass: "upload-photo-dark text-center btn-sm btn-primary",
    on: {
      "click": function ($event) {
        return _vm.openmodalcapture('mykad-back');
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("capture-picture")))])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }