var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.$t("testator")))]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("fullname")))]), _c('p', {
    staticClass: "input-control-static"
  }, [_vm._v(" " + _vm._s(_vm.wasiat.user.designation ? _vm.wasiat.user.designation : "-") + " " + _vm._s(_vm.wasiat.user.name ? _vm.wasiat.user.name : "-") + " ")])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('p', {
    staticClass: "input-control-static"
  }, [_vm._v(" " + _vm._s(_vm.wasiat.user_detail ? _vm.wasiat.user_detail.ic_number : "-") + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("old-ic")))]), _c('p', {
    staticClass: "input-control-static"
  }, [_vm._v(" " + _vm._s(_vm.wasiat.user_detail.old_ic_number ? _vm.wasiat.user_detail.old_ic_number : "-") + " ")])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("gender")))]), _vm.wasiat.user_detail.gender == 1 ? _c('p', {
    staticClass: "input-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("male")) + " ")]) : _vm._e(), _vm.wasiat.user_detail.gender != 1 ? _c('p', {
    staticClass: "input-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("female")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("religion")))]), _c('p', {
    staticClass: "input-control-static"
  }, [_vm._v(" " + _vm._s(_vm.wasiat.user_detail.religion ? _vm.wasiat.user_detail.religion : "-") + " ")])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("race")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.wasiat.user_detail.race ? _vm.wasiat.user_detail.race : "-") + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("nationality")))]), _vm.wasiat.user_detail.nationality == 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("citizen")) + " ")]) : _vm._e(), _vm.wasiat.user_detail.nationality != 1 ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.$t("not-citizen")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("email")))]), _c('p', {
    staticClass: "input-control-static"
  }, [_vm._v(" " + _vm._s(_vm.wasiat.user_detail.email ? _vm.wasiat.user_detail.email : "-") + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("mobile-no")))]), _c('p', {
    staticClass: "input-control-static"
  }, [_vm._v(" " + _vm._s(_vm.wasiat.user_detail.mobile_number ? _vm.wasiat.user_detail.mobile_number : "-") + " ")])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("home-telno")))]), _c('p', {
    staticClass: "input-control-static"
  }, [_vm._v(" " + _vm._s(_vm.wasiat.user_detail.home_phone_number ? _vm.wasiat.user_detail.home_phone_number : "-") + " ")])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("address")))]), _c('p', {
    staticClass: "form-control-static",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.fullAddress ? _vm.fullAddress : "-") + " ")])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("mykadcopy-front")))]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    }
  }, [_vm.mykad.frontUrl ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.mykad.frontUrl + ');',
    attrs: {
      "for": "upload-photo-2"
    }
  }) : _vm._e()])]), _c('div', {
    staticClass: "d-flex justify-content-center mt-2"
  }, [_c('b-button', {
    staticClass: "btn-sm btn-primary m-1",
    on: {
      "click": function ($event) {
        return _vm.showIc('front');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")])], 1)]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("mykadcopy-back")))]), _c('div', {
    staticClass: "card rounded shadow bg-dark"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center bd-highlight",
    staticStyle: {
      "height": "150px"
    }
  }, [_vm.mykad.backUrl ? _c('label', {
    staticStyle: {
      "border-radius": "5px",
      "margin-bottom": "0",
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",
      "height": "100%",
      "width": "100%"
    },
    style: 'background-image: url(' + _vm.mykad.backUrl + ');',
    attrs: {
      "for": "upload-photo-2"
    }
  }) : _vm._e()])]), _c('div', {
    staticClass: "d-flex justify-content-center mt-2"
  }, [_c('b-button', {
    staticClass: "btn-sm btn-primary m-1",
    on: {
      "click": function ($event) {
        return _vm.showIc('back');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")])], 1)])])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("next")) + " "), _vm.submit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }